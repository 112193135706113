import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { AppPreloadingStrategy } from '../app_preloading_strategy';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SafePipe } from '../safe.pipe';
@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      DataTablesModule,
      NgZorroAntdModule,
      CKEditorModule,
      NgxPermissionsModule

   ],
   declarations: [
      SafePipe
   ],
   exports: [
      FormsModule, ReactiveFormsModule, DataTablesModule, CommonModule, NgZorroAntdModule,
      CKEditorModule, NgxPermissionsModule, SafePipe
   ],
   schemas: [
      NO_ERRORS_SCHEMA
   ],
   providers: [{ provide: NZ_I18N, useValue: en_US }, AppPreloadingStrategy],
})
export class SharedModule { }
