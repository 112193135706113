
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../model/auth.service';
import { Router } from '@angular/router';
@Injectable()
export class AclPermisson implements CanActivate {
   constructor(private auth: AuthService,
      private myRoute: Router) {
   }
   canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (this.auth.getUserRoles().permissions.indexOf(next.data.permissions.only) != -1)
         return true;
      else {
         this.myRoute.navigate(["/app"]);
         return false;
      }

   }
}