import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AppPreloadingStrategy } from './app_preloading_strategy';
import { LayoutComponent } from './layout/layout.component';
import { CanActivate } from '@angular/router/src/utils/preactivation';
import { AuthGuard } from './guard/auth.guard';
import { GuestGuard } from './guard/guest.guard';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { LearnerLayoutComponent } from './layout/learner-layout/learner-layout.component';
import { StudentCourseComponent } from './pages/student-course/student-course.component';
import { UsersComponent } from './users/users.component';
import { StudentCourseDetailComponent } from './pages/student-course-detail/student-course-detail.component';
import { CourseQuizComponent } from './pages/course-quiz/course-quiz.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
const routes: Routes = [
  { path: '', redirectTo: 'app', pathMatch: 'full', canActivate: [AuthGuard], },
  {
    path: 'app',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { breadcrumb: ['Dashboard'] } },
     
      {
        path: 'client',
        loadChildren: './pages/admin/admin.module#AdminModule',
        canActivate: [AuthGuard],
        data: { preload: false, delay: false },
      },
      {
        path: 'course',
        canActivate: [AuthGuard],
        loadChildren: './pages/category/category.module#CategoryModule',

        data: { preload: false, delay: false },
      },
      {
        path: 'lesson',
        canActivate: [AuthGuard],
        loadChildren: './pages/lesson/lesson.module#LessonModule',

        data: { preload: false, delay: false },
      },
      {
        path: 'user',
        canActivate: [AuthGuard],
        loadChildren: './pages/learners/learners.module#LearnerModule',

        data: { preload: false, delay: false },
      },
      {
        path: 'role',
        canActivate: [AuthGuard],
        loadChildren: './pages/role-permission/role-permission.module#RolePermissionModule',

        data: { preload: false, delay: false },
      },
    ]
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'courses', pathMatch: 'full' },
      { path: 'courses', component: StudentCourseComponent, canActivate: [AuthGuard], },
      { path: 'courses/detail/:id', component: StudentCourseDetailComponent, canActivate: [AuthGuard], },
      { path: 'courses/quiz/:id', component: CourseQuizComponent, canActivate: [AuthGuard], },
    ]
  },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'login/:username', loadChildren: './login/login.module#LoginModule', canActivate: [GuestGuard], data: { preload: true, delay: false }, },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: AppPreloadingStrategy, initialNavigation: 'enabled' }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
