
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../model/auth.service';
import { Router } from '@angular/router';
@Injectable()
export class GuestGuard implements CanActivate {
   constructor(private auth: AuthService,
      private myRoute: Router) {
   }
   canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (!this.auth.isLoggednIn()) {
         return true;
      } else {
         if (this.auth.getUser().role == 'STUDENT')
            this.myRoute.navigate(["/users"]);
         else
            this.myRoute.navigate(["/"]);
         return false;
      }
   }
}