import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, SafePipe } from './app.component';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DataTablesModule } from 'angular-datatables';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { AppPreloadingStrategy } from './app_preloading_strategy';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LayoutComponent } from './layout/layout.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { AuthGuard } from './guard/auth.guard';
import { AuthService } from './model/auth.service';
import { GuestGuard } from './guard/guest.guard';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { LearnerLayoutComponent } from './layout/learner-layout/learner-layout.component';
import { StudentCourseComponent } from './pages/student-course/student-course.component';
import { UsersComponent } from './users/users.component';
import { StudentCourseDetailComponent } from './pages/student-course-detail/student-course-detail.component';
import { CourseQuizComponent } from './pages/course-quiz/course-quiz.component';
import { AclPermisson } from './guard/acl-permisson.guard';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { SharedModule } from './shared/shared.module';
import {DatePipe} from '@angular/common';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LayoutComponent,
    PageNotFoundComponent,
    SafePipe,
    LearnerLayoutComponent,
    StudentCourseComponent,
    UsersComponent,
    StudentCourseDetailComponent,
    CourseQuizComponent,
    ResetPasswordComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    NgZorroAntdModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DataTablesModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    NgxPermissionsModule.forRoot(),

  ],
  providers: [
    AppPreloadingStrategy,DatePipe,AuthGuard, AuthService, GuestGuard, AclPermisson, NgxPermissionsService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (ds: AuthService, ps: NgxPermissionsService) => {
    //     return ds.getUserRolesAsync().then((data) => ps.loadPermissions(data.permissions)
    //     )
    //   },
    //   deps: [NgxPermissionsService, AuthService],
    //   multi: true
    // },
    { provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
