import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { User } from '../class/user'
import { Router } from '@angular/router'; import { RolePermisson } from '../data/role-permission';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
;
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private url = environment.apiUrl;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json"
    })
  };
  constructor(private http: HttpClient, private router: Router, private permissionsService: NgxPermissionsService, private roleService: NgxRolesService) {
  }
  public login(user) {
    let data = {
      email: user.userName,
      user_name: user.username,
      password: user.password
    }
    return this.http.post(this.url + 'users/login', data, this.httpOptions);
  }
  public forgotPassword(email) {
    return this.http.post(this.url + 'users/forgot_password', { email: email }, this.httpOptions);
  }
  public verifyOTP(otpDetail) {
    const data = {
      user_id: otpDetail.userId,
      otp: otpDetail.otp,
      email: otpDetail.email,
    };
    return this.http.post(this.url + 'users/otp_verify', data, this.httpOptions);
  }
  public resetPassword(passwordInfo) {
    const data = {
      user_id: passwordInfo.userId,
      password: passwordInfo.password,
    };
    return this.http.post(this.url + 'users/reset_password', data, this.httpOptions);
  }
  sendToken(token: string) {
    localStorage.setItem("aml_user_token", token)
  }
  getToken() {
    return localStorage.getItem("aml_user")['token'];
  }
  getUser(): User {
    return JSON.parse(localStorage.getItem('aml_user'));
  }
  setUser(user) {
    localStorage.setItem("aml_user", JSON.stringify(user));
  }
  setUserRoles(rolePermisson: RolePermisson) {
    localStorage.setItem("aml_user_roles", JSON.stringify(rolePermisson));
  }
  getUserRoles(): RolePermisson {
    return JSON.parse(localStorage.getItem('aml_user_roles'));
  }
  getUserRolesAsync(): Promise<RolePermisson> {
    return JSON.parse(localStorage.getItem('aml_user_roles'));
  }
  getUserRoleList() {

    let data = {
      user_id: this.getUser().id,
    }
    return this.http.post(this.url + 'role_operation/list', data, this.httpOptions);
  }
  setUserName(userName) {
    localStorage.setItem("aml_user_name", JSON.stringify(userName));
  }
  setTempUser(id){
    localStorage.setItem("temp_user", JSON.stringify(id));
  }
  getTempUser(){
    return JSON.parse(localStorage.getItem('temp_user'));
  }
  getUserName() {
    return JSON.parse(localStorage.getItem('aml_user_name'));
  }
  isLoggednIn() {
    return this.getUser() !== null;
  }
  isTempLogin() {
    return this.getTempUser() !== null;
  }
  clearTempLog(){
    localStorage.removeItem("temp_user");
  }
  logout() {
    let url = "/login/" + this.getUserName();
    //console.log(url)
    localStorage.removeItem("aml_user");
    localStorage.removeItem("aml_user_roles");
    localStorage.removeItem("temp_user");
    this.permissionsService.flushPermissions();
    this.roleService.flushRoles();
    this.router.navigate([url]);
  }



}
