import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/model/auth.service';
import { NzMessageService } from 'ng-zorro-antd';
import { Router, ActivatedRoute } from '@angular/router';
import { RolePermisson, Permisson, roles, permissons, CHECK_PERM } from '../../data/role-permission';
import { NgxRolesService, NgxPermissionsService } from 'ngx-permissions';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  loginLoading:Boolean=false;
  //private username: string;
  constructor(private permissionsService: NgxPermissionsService, private roleService: NgxRolesService, private fb: FormBuilder, private _authService: AuthService, private message: NzMessageService, private router: Router, private route: ActivatedRoute) {
   // this.route.params.subscribe(param => this.username = param['username']);
   if(!(this._authService.isTempLogin())){
     this.router.navigate(['login', this._authService.getUserName()]);
   }

  }
  ngOnInit() {
    this.resetPasswordForm = this.fb.group({
      userId: [this._authService.getTempUser(), [Validators.required]],
      password: ['', [Validators.required]],
      confirm: ['', [this.confirmValidator]],
    });
  }
  ngOnDestroy(){
    this._authService.clearTempLog();
  }

 submitResetForm(): void {
    for (const i in this.resetPasswordForm.controls) {
      this.resetPasswordForm.controls[i].markAsDirty();
      this.resetPasswordForm.controls[i].updateValueAndValidity();
    }
    if (this.resetPasswordForm.valid) {
      this.loginLoading = true;
      this._authService.resetPassword(this.resetPasswordForm.value).subscribe(result => {
        this.loginLoading = false;
        if (result['status'] == "success") {
          this.message.create('success', 'Password reset successfully');
          this._authService.logout();
          //this.router.navigate(['login', this._authService.getUser().userName]);
        }
        else {
          this.message.create('error', result['msg']);
          //this.router.navigate(['login', this.username]);
        }

      }, error => {
        this.loginLoading = false;
        this.message.create('error', error.statusText);
      });
    }
  }


  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.resetPasswordForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  }

}
