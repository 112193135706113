import { Component, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { AuthService } from '../model/auth.service';
import { ActivatedRoute } from '@angular/router';
import { User } from '../class/user';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
@Component({
  selector: 'app-root',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  public user: User;
  breadcrumb: string[] = [];
  logo = 'assets/images/logo2.jpg';
  isCollapsed = false;
  triggerTemplate: TemplateRef<void> | null = null;
  @ViewChild('trigger') customTrigger: TemplateRef<void>;

  /** custom trigger can be TemplateRef **/
  changeTrigger(): void {
    this.triggerTemplate = this.customTrigger;
  }
  constructor(public authService: AuthService, public route: ActivatedRoute, private permissionsService: NgxPermissionsService, private roleService: NgxRolesService) {
    let role = this.roleService.roles$.subscribe((data) => {

    })

  }
  ngOnInit() {

    this.user = this.authService.getUser();

    if (this.authService.getUserRoles())
      this.permissionsService.loadPermissions(this.authService.getUserRoles().permissions);
    
  }

}

