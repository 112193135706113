import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/model/auth.service';
import { CategoryService } from 'src/app/model/category.service';
import { NzMessageService } from 'ng-zorro-antd';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
export class Question {
  id: number;
  questionText: string;
  option1: string;
  option2: string;
  option3: string;
  option4: string;
  answer: string;
}
export class Category {
  id: number;
  categoryName: string;
  totalMark: number;
  passMark: number;
}
@Component({
  selector: 'app-course-quiz',
  templateUrl: './course-quiz.component.html',
  styleUrls: ['./course-quiz.component.scss']
})
export class CourseQuizComponent implements OnInit {
  public downloadUrl = environment.apiUrl+'certificate/download/';
  private studentCourseId: number;
  public questions: Array<Question> = [];
  public category: Category;
  currentQuestion: Question;
  previousQuestion: Question;
  nextQuestion: Question;
  currentQuestionIndex: number;
  previousQuestionIndex: number;
  nextQuestionIndex: number;
  totalQuestions: number;
  radioValue = [];
  result: boolean = false;
  resultSubmited: boolean = false;
  resultPercent: number;
  userrole:any;
  style = {
    display: 'block',
    lineHeight: '30px'
  };
  constructor(public authService: AuthService, private categoryService: CategoryService, private message: NzMessageService, private route: ActivatedRoute) {
    this.route.params.subscribe(params => this.studentCourseId = params['id']);
  }

  ngOnInit() {
    this.userrole=this.authService.getUser().role

    this.categoryService.getAllQuizQuestions(this.studentCourseId).subscribe(result => {
      if (result['status'] == "success") {
        this.questions = result['quiz_questions'].map((question): Question => ({ id: question.id, questionText: question.question, option1: question.option1, option2: question.option2, option3: question.option3, option4: question.option4, answer: question.answer }));
        let student_category = result['student_category_list'];
        this.category = new Category;
        this.category.id = student_category.id;
        this.category.categoryName = student_category.name;
        this.category.passMark = student_category.pass_mark;
        this.category.totalMark = student_category.total_mark;

        this.getQuestion(this.questions[0], 0);
        this.totalQuestions = this.questions.length;

        for (let i = 0; i < this.questions.length; i++) {
          this.radioValue[i] = null;
        }
      }
    });

  }

  getQuestion(question: Question, questionIndex: number) {
    //console.log(question);
    this.currentQuestion = question;
    this.currentQuestionIndex = questionIndex;
    this.previousQuestionIndex = questionIndex - 1;
    this.nextQuestionIndex = questionIndex + 1;
    if (this.questions[questionIndex - 1])
      this.previousQuestion = this.questions[questionIndex - 1];
    else
      this.previousQuestion = null
    if (this.questions[questionIndex + 1])
      this.nextQuestion = this.questions[questionIndex + 1];
    else
      this.nextQuestion = null

  }
  submitAnswer() {
   
    let correct_answers = this.questions.filter((question, questionIndex) => {
      //let index = this.radioValue.indexOf(question.answer);
      return this.radioValue[questionIndex]==question.answer;
     // return index == questionIndex;
    });
    console.log(correct_answers);
    let result_percentage = Math.round((correct_answers.length / this.questions.length) * this.category.totalMark);
    if (result_percentage >= this.category.passMark) {
      this.result = true;
    }
    else {
      this.result = false;
    }
    this.categoryService.postQuizResult(this.authService.getUser().id, result_percentage, this.result, this.studentCourseId).subscribe(result => {
      if (result['status'] == 'success') {
        this.resultSubmited = true;
        this.resultPercent = result_percentage;
      }
    });
  }
  tryAgain() {
    this.getQuestion(this.questions[0], 0);
    for (let i = 0; i < this.questions.length; i++) {
      this.radioValue[i] = null;

    }
    this.resultSubmited = false;
  }
}
//20/30*100