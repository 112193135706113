import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/model/category.service';
import { AuthService } from 'src/app/model/auth.service';
import { Student } from '../category/category/view-category/view-category.component';
import { StudentModule, StudentLessons } from '../student-course-detail/student-course-detail.component';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  studentQuiz: any;
  courseStudents: any;
  categoryReport: Array<any> = []
  userlimit:any;
  categoryReport2: Array<any> = []
  categoryReportHeader2 = ["Student Name","Userid ","Batch Details","Shift Timing","Student Id","Course Name", "Module Name", "Lesson Name", "Result",
  "Date and Time","Attempt", "Quiz Time", "Total Score", "User Score", "User Percentage","Pass Score", 
   "Pass Percentage","Correct Answer", "Incorrect Answer","Partially Answer"]
  categoryReportHeader = ["Course Name", "Students Count", "New", "Progress", "Compeleted", "Quiz Attended", "Pass", "Fail"]
  constructor(private categoryService: CategoryService,private datePipe: DatePipe, public authService: AuthService,public router:Router) { }

  ngOnInit() {
    if (this.authService.getUser().role == 'STUDENT')
      this.router.navigate(['/users/courses']);
    this.categoryService.clientCategoryReport(this.authService.getUser().id).subscribe(result => {
      if (result['status'] == 'success') {
        this.categoryReport = result['client_dashboard'].map(report => ({ categoryId: report.category_id, userCategoryId: report.user_category_id, categoryName: report.category_name, studentCount: report.total_student, new: report.student_new, onProgress: report.student_onprogress, completed: report.student_completed, pass: report.student_quiz_pass, fail: report.student_quiz_fail, attended: report.student_quiz_attended }))
      }
this.categoryService.getuserlimit(this.authService.getUser().id).subscribe(result=>{
      this.userlimit=result['data']
      // console.log('lim',this.userlimit)
    //   // this.categoryReport = result['client_dashboard'].push(report => ({ limit: this.userlimit.limit}))
    for (var i = 0; i < this.userlimit.length; i++) {
      // console.log("limit",this.userlimit[i].limit)
      // console.log('cat',this.categoryReport[i])
           this.categoryReport[i].limit=this.userlimit[i].limit
                 }
                //  console.log('ne',this.categoryReport)
    })
    })

  }
  getCourseDetailedExport(categoryId) {
    this.categoryService.getCourseStudents(this.authService.getUser().id, categoryId).subscribe(result => {
      let studentQuiz;
      let courseStudents;
      if (result['status'] == 'success') {
        let userCategoryId = result['course_list'].id
        this.categoryService.courseReport(userCategoryId).subscribe(result => {
          this.studentQuiz = result['student_lists'];
          // console.log(this.studentQuiz)
        })
        console.log('stcl',result['student_category_lists']);
        this.courseStudents = result['student_category_lists'];

      }

    }, error => { }, () => { setTimeout(() => { this.exportIndividualCourse(); }, 1000) });

  }
  exportIndividualCourse() {
    var courseName = '';
  //  let columnName = ['User Name', 'Email ID', 'Course Name', 'Course Status', 'Quiz Status', 'Attempt 1', 'Attempt 2', 'Attempt 3', 'Attempt 4', 'Attempt 5','Passing Mark Scored'];
    let columnName = ['User Name', 'Email ID', 'Course Name', 'Course Status', 'Quiz Status','Completion Date', 'Download Certificate'];
    let csv = this.courseStudents.map((element, index) => {
      courseName = element.category_name.split(" ").join('-') + '-report';
      //console.log(element);
      let all_marks = this.studentQuiz[index].quiz_questions.map(mark => mark.total_mark);
      let maximum = 0;
      if(all_marks.length)
         maximum=Math.max(...all_marks);
      console.log(maximum)
      // let marks = all_marks.slice(0, 5);
      // for(let i=marks.length;i<5;i++){
      //   marks.push('-');
      // }
      let quiz_status = '';
      let url = '';
      let com_date = '';
      if (element.quiz_status == 'Completed')
      {
        quiz_status = 'Pass';
         url = 'https://www.amldesk.com/aml-trainning/public/index.php/api/certificate/download/'+element.student_category_id
         com_date =this.datePipe.transform(element.completion_date.split(" ")[0],"dd-MMM-yyyy")
         console.log(this.datePipe.transform(com_date,"dd-MMM-yyyy"))
        }       
        
      else if (all_marks.length==0)
        quiz_status = 'Not Attended';
      else
        quiz_status = 'Fail';
        // let date=element.completion_date.date.split(" ")[0]
        
        // console.log('da',element.completion_date.date.split(" ")[0])
      return `${element.name},${element.email},${element.category_name},${element.status},${quiz_status},${com_date},${url},`;
      // ${maximum},
    });
    //console.log(csv);
    csv.unshift(columnName.join(',')); //[columaarry,st1,st2...stn]

    let csvArray = csv.join('\r\n');

    //return false; // <a href="url" dowload="filename.csv" >
    var a = document.createElement('a');
    var blob = new Blob([csvArray], { type: 'text/csv' }),
      url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = courseName + ".csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();

  }
  exportExcel() {
    this.downloadFile(this.categoryReportHeader, this.categoryReport);
  }
  downloadFile(columns, data: any) {
    console.log(data);
    //const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    header.splice(0, 1);
    let csv = data.map(row => {
      delete row['userCategoryId'];
      return `${row["categoryName"]},${row["studentCount"]},${row["new"]},${row["onProgress"]}, ${row["completed"]}, ${row["attended"]}, ${row["pass"]}, ${row["fail"]}`;
    });
    csv.unshift(columns.join(','));
    let csvArray = csv.join('\r\n');
    //console.log(csvArray);
    //return false;
    var a = document.createElement('a');
    var blob = new Blob([csvArray], { type: 'text/csv' }),
      url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = "Course_Report.csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  excel(id)
  {
    console.log('s',this.authService.getUser().id)
      // if (this.authService.getUser().role == 'STUDENT')
        // this.router.navigate(['/users/courses']);
      this.categoryService.getStudentDetail2(this.authService.getUser().id).subscribe(result => {
        if (result['status'] == 'success') {
          this.categoryReport2 = result['data'].map(report => ({ 
            Id: report.id, user_Id: report.user_id,
            categoryId: report.category_id, userCategoryId: report.student_category_id,
            categoryModuleId: report.sub_category_module_id, lessonId: report.lesson_id,
             studentslessonId: report.student_lesson_id,result: report.result_pass_fail,
             quiz_time_min:report.quiz_time_min,userpercentage:report.user_percentage,
             totalscore:report.total_score, userscore:report.user_score  ,
              passpercentage:report.pass_percentage,passscore:report.pass_score,
              correctanswer: report.correct_answers,incorrectanswer: report.incorrect_answers,
             unanswered:report.unanswered,answered:report.answered,
             partiallyanswer:report.partially_answer, 
             usquiz_result_created_timeerscore:report.quiz_result_created_time  ,
              record_created_date_time:report.record_created_date_time,
              used_attempt:report.used_attempt,client_id:report.client_id,
             categoryName: report.category_name, modulename: report.module_name,
              lessonname: report.lesson_name, userstudentname: report.user_student_name, 
              clientname: report.client_name, phone: report.phone  , 
              email: report.email, departmentname: report.department_name, teamname: report.team_name  , 
              employee: report.employee_id}))
        }
  
  this.exportExcel2();
      })
  }
   exportExcel2() {
      this.downloadFile2(this.categoryReportHeader2, this.categoryReport2);
    }
    downloadFile2(columns, data: any) {
      console.log(data);
      //const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
      const header = Object.keys(data[0]);
      header.splice(0, 1);
      let csv = data.map(row => {
        delete row['userCategoryId'];
        return `${row["userstudentname"]},${row["email"]},${row["departmentname"]},${row["teamname"]},${row["employee"]},${row["categoryName"]},${row["modulename"]},${row["lessonname"]},${row["result"]},${row["record_created_date_time"]},${row["used_attempt"]}, ${row["quiz_time_min"]}, ${row["totalscore"]}, ${row["userscore"]},${row["userpercentage"]},${row["passscore"]},${row["passpercentage"]},${row["correctanswer"]},${row["incorrectanswer"]},${row["partiallyanswer"]}`;
      });
      csv.unshift(columns.join(','));
      let csvArray = csv.join('\r\n');
      //console.log(csvArray);
      //return false;
      var a = document.createElement('a');
      var blob = new Blob([csvArray], { type: 'text/csv' }),
        url = window.URL.createObjectURL(blob);
  
      a.href = url;
      a.download = "Student_Report.csv";
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
}
