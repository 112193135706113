import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { Category, SubCategory } from '../class/category';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Question } from '../pages/course-quiz/course-quiz.component';
@Injectable({
   providedIn: 'root'
})
export class CategoryService {
   url3:any;
   url2
   private url = environment.apiUrl;
   public httpOptions = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json',
         "Accept": "application/json"
      })
   };
   constructor(private http: HttpClient) {
      this.url3="https://www.amldesk.com/increasemarks_api/index.php/";
      this.url2="https://increasemarks.com/im_quiz/";

   }
   public courseReport(userCategoryId) {
      const data = {
         user_category_id: userCategoryId,
      };
      //console.log(data);
      return this.http.post(this.url + 'student_excel/list', data, this.httpOptions);
   }
   public getCategoryList(userId) {
      const data = {
         user_id: userId,
      };
      return this.http.post(this.url + 'category/list', data, this.httpOptions);
   }
   public getCategoriesLessonList(userId){
    const data = {
      user_id: userId,
   };
   return this.http.post(this.url + 'courses_lessons/list', data, this.httpOptions);
   }

   public addCategory(category) {
      // console.log(category.subCategories.join(','));
      const formData: FormData = new FormData();
      formData.append('name', category.name);
      category.subCategories.forEach((id, index) => formData.append(`sub_category_ids[${index}]`, id));
      // formData.append('sub_category_ids', JSON.stringify(category.subCategories));
      formData.append('image', category.banner, category.banner.name);
      formData.append('description', category.description);
      // new Response(formData).text().then(console.log)
      return this.http.post(this.url + 'category/add', formData);
   }
   public categoryDetail(id) {
      const data = {
         category_id: id,
      };

      return this.http.post(this.url + 'category/edit', data, this.httpOptions);
   }
   public updateCategory(category) {
      const data = {
         category_id: category.id,
         name: category.name,
         description: category.description
      };

      return this.http.post(this.url + 'category/update', data, this.httpOptions);
   }
   public getSubCategoryList() {
      return this.http.get(this.url + 'sub_category/list');
   }
   public addSubCategory(sub_category) {
      const data = {
         name: sub_category.name,
         // category_ids: sub_category.categories
      };
      return this.http.post(this.url + 'sub_category/add', data, this.httpOptions);
   }

   public subCategoryDetail(id) {
      const data = {
         sub_category_id: id,
      };

      return this.http.post(this.url + 'sub_category/edit', data, this.httpOptions);
   }
   public updateSubCategory(sub_category) {
      const data = {
         sub_category_id: sub_category.id,
         name: sub_category.name,
         //  category_ids: sub_category.categories
      }

      return this.http.post(this.url + 'sub_category/update', data, this.httpOptions);
   }
   public changeCategoryStatus(category_id, status) {
      const data = {
         category_id: category_id,
         status: status
      }
      return this.http.post(this.url + 'category/status', data, this.httpOptions);
   }
   public changeSubCategoryStatus(sub_category_id, status) {
      let data = {
         sub_category_id: sub_category_id,
         status: status
      }
      return this.http.post(this.url + 'sub_category/status', data, this.httpOptions);
   }
   public updateCourseModules(courseModules) {
      let data = {
         sub_category_ids: courseModules.modules,
         category_id: courseModules.courseId
      }
      return this.http.post(this.url + 'course_module/add', data, this.httpOptions);
   }
   public updateCourseLessons(courseModulelessons) {
      let data = {
         category_sub_category_detail_id: courseModulelessons.courseModuleId,
         lesson_ids: courseModulelessons.lessons
      }
      return this.http.post(this.url + 'course_lesson/add', data, this.httpOptions);
   }
   public getCourseModules(moduleId) {
      let data = {
         category_id: moduleId
      }
      return this.http.post(this.url + 'course_module/list', data, this.httpOptions);
   }
   public getCourseStudents(user_id, courseId) {
      let data = {
         user_id: user_id,
         category_id: courseId
      }
      return this.http.post(this.url + 'student_course/view', data, this.httpOptions);
   }
   public addCourseQuestions(categoryQuiz) {
      let data = {
         category_id: categoryQuiz.categoryId,
      }
      if (categoryQuiz.questions.length > 0) {
         data['quiz_questions'] = categoryQuiz.questions.map(question => {
            let answer = null;
            if (question.answer == 1)
               answer = question.option1;
            else if (question.answer == 2)
               answer = question.option2;
            else if (question.answer == 3)
               answer = question.option3;
            else if (question.answer == 4)
               answer = question.option4;
            return { question: question.questionText, option1: question.option1, option2: question.option2, option3: question.option3, option4: question.option4, answer: answer }
         })
      }
      return this.http.post(this.url + 'quiz_question/add', data, this.httpOptions);
   }
   public uploadQuestion(file, categoryId) {
      let fd = new FormData();
      fd.append('category_id', categoryId);
      fd.append('file', file, file.name);
      return this.http.post(this.url + 'quiz_question_excel/add', fd);
   }
   public updateCourseQuestion(question: Question) {
      let data = {
         quiz_question_id: question.id,
         question: question.questionText,
         option1: question.option1,
         option2: question.option2,
         option3: question.option3,
         option4: question.option4,
         answer: question.answer
      }

      return this.http.post(this.url + 'quiz_question/update', data, this.httpOptions);
   }
   public getAllQuestions(courseId) {
      let data = {
         category_id: courseId,
      }
      return this.http.post(this.url + 'quiz_question/list', data, this.httpOptions);
   }
   public getAllQuizQuestions(studentCourseId) {
      let data = {
         student_category_id: studentCourseId,
      }
      return this.http.post(this.url + 'student_category_quiz/list', data, this.httpOptions);
   }
   public postQuizResult(user_id, total_mark, status, student_category_id) {
      let data = {
         user_id: user_id,
         total_mark: total_mark,
         status: Number(status),
         student_category_id: student_category_id
      }
      //console.log(data);
      return this.http.post(this.url + 'quiz_question/result', data, this.httpOptions);
   }
   public getStudentCourse(user_id) {
      let data = {
         user_id: user_id
      }
      //console.log(data);
      return this.http.post(this.url + 'student_category/list', data, this.httpOptions);
   }
   public getStudentCourseDetail(studentCourseId) {
      let data = {
         student_category_id: studentCourseId
      }
      return this.http.post(this.url + 'student_course/list', data, this.httpOptions);
   }
   public getStudentCourseModuleLesson(studentCourseModuleLessonId) {
      let data = {
         student_lesson_id: studentCourseModuleLessonId
      }
      return this.http.post(this.url + 'student_lesson_detail/list', data, this.httpOptions);
   }
   public studentLessonComplete(student_lesson_id, student_next_lesson_id, duration) {
      let data = {
         student_lesson_id: student_lesson_id,
         student_next_lesson_id: student_next_lesson_id,
         duration: duration
      }
      return this.http.post(this.url + 'student_lesson_complete/list', data, this.httpOptions);
   }
   public uploadBanner(image, categoryId) {
      let fd = new FormData();
      fd.append('category_id', categoryId);
      fd.append('image', image, image.name);
      return this.http.post(this.url + 'category_image/update', fd);
   }
   public clientCategoryReport(clientId) {
      const data = {
         user_id: clientId
      }
      return this.http.post(this.url + 'client/dashboard', data, this.httpOptions);
   }
   public updateQuizMark(passMark, totalMark, categoryId) {
      const data = {
         total_mark: totalMark,
         pass_mark: passMark,
         category_id: categoryId
      }
      //console.log(data);
      return this.http.post(this.url + 'quiz_question_total/update', data, this.httpOptions);
   }
   public deleteCourse(courseId) {
      const data = {
         category_id: courseId
      }
      return this.http.post(this.url + 'category/delete', data, this.httpOptions);
   }
   public deleteModule(moduleId) {
      const data = {
         sub_category_id: moduleId
      }
      return this.http.post(this.url + 'sub_category/delete', data, this.httpOptions);
   }
   public getuserlimit(user_id) {
      return this.http.get(this.url3 + 'get_limit?id='+user_id);
   }
   public getclientlearn(user_id) {
      return this.http.get(this.url3 + 'get_client_learn?id='+user_id);
   }
   public getStudentDetail2(user_id) {
      return this.http.get(this.url2 + 'quiz_result.php?user_id=&id=&category_id=&module_id=&lesson_id=&client_id='+user_id);
   }
}
