import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/model/auth.service';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from 'src/app/model/category.service';
import { NzMessageService } from 'ng-zorro-antd';
export class StudentCourseDetail {
  courseId: number;
  courseName: string;
  studentCourseId: number;
  status: string;
  modules: Array<StudentModule>

}
export class StudentModule {
  moduleId: number;
  studentModuleId: number;
  moduleName: string;
  moduleStatus: string;
  lessons: Array<StudentLessons>
}
export class StudentLessons {
  lessonId: number;
  studentLessonId: number;
  lessonName: string;
  status: boolean;
  progressStatus: string;
  description: string;
}
@Component({
  selector: 'app-student-course-detail',
  templateUrl: './student-course-detail.component.html',
  styleUrls: ['./student-course-detail.component.scss']
})
export class StudentCourseDetailComponent implements OnInit {
  isCollapsed = false;
  studentCourseId: number;
  studentCourseDetail: StudentCourseDetail;
  selectedModule: StudentModule;
  seletcedLesson: StudentLessons;
  previousLesson: StudentLessons;
  nextLesson: StudentLessons;
  currentModuleIndex: number;
  currentLessonIndex: number;
  previousLessonModuleIndex: number;
  previousLessonIndex: number;
  nextLessonModuleIndex: number;
  nextLessonIndex: number;
  takenTime = 0;
  enableQuiz: boolean = false;
  lessonLength = 0;
  completedLessonLength = 0;
  openSidebarMenu: Array<Boolean> = [];
  quizStatus: boolean = false;
  userrole:any;
  fullscreen:any;
  constructor(public authService: AuthService,
    private route: ActivatedRoute,
    private courseService: CategoryService,
    private message: NzMessageService) {

  }

  ngOnInit() {
    this.userrole=this.authService.getUser().role
    this.fullscreen="0"
    this.route.params.subscribe(params => {
      this.studentCourseId = params['id'];
      this.getCourseDetail();
    });
  }
    ex()
  {
    if (this.fullscreen=="1") {
      this.fullscreen="0"
    }
     else if (this.fullscreen=="0") {
      this.fullscreen="1"
    }
    else{
    this.fullscreen="1"
  }
  console.log('this.fullscreen',this.fullscreen)
  }
  getCourseDetail() {
    this.courseService.getStudentCourseDetail(this.studentCourseId).subscribe(result => {
      //console.log(result);
      if (result['status'] == 'success') {
        this.quizStatus = Boolean(result['quiz_question']);
        let course = result['student_categories'];
        this.studentCourseDetail = new StudentCourseDetail;
        this.studentCourseDetail.courseId = course.category_id;
        this.studentCourseDetail.courseName = course.category_name;
        this.studentCourseDetail.studentCourseId = course.id;
        this.studentCourseDetail.status = course.status;
        let modules: Array<StudentModule> = [];
        this.lessonLength = 0;
        course.module_lists.forEach((module, moduleIndex) => {
          let lessons: Array<StudentLessons> = [];
          this.openSidebarMenu[moduleIndex] = true;
          module.lessons.forEach((lesson, lessonIndex) => {
            this.lessonLength += 1;
            if (lesson.status == "Completed")
              this.completedLessonLength += 1;
            lessons.push({
              lessonId: lesson.lesson_id,
              studentLessonId: lesson.id,
              lessonName: lesson.lesson_name,
              status: Boolean(Number(lesson.view_status)),
              progressStatus: lesson.status,
              description: lesson.description

            })

          })
          modules.push({
            moduleId: module.module_id,
            studentModuleId: module.id,
            moduleName: module.module_name,
            lessons: lessons,
            moduleStatus: module.status,
          })
        });
        this.studentCourseDetail.modules = modules;
        // this.studentCourseDetail.modules.forEach((module, moduleIndex) => {
        //   module.lessons.forEach((lesson, lessonIndex) => {
        //     if (lesson.status && lesson.progressStatus == "Onprogress") {
        //       this.getLesson(module, moduleIndex, lesson, lessonIndex);
        //     }
        //   })
        // })
        this.getLesson(modules[0], 0, modules[0].lessons[0], 0);

      }
    })
  }
  completeCourse() {
    this.courseService.studentLessonComplete(this.seletcedLesson.studentLessonId, null, "2:30").subscribe(result => {
      if (result['status'] == 'success') {
        this.message.create('success', "Course Completed successfully");
        if (this.studentCourseDetail.modules[this.currentModuleIndex].lessons[this.currentLessonIndex].progressStatus == "Onprogress") {
          this.studentCourseDetail.modules[this.currentModuleIndex].lessons[this.currentLessonIndex].progressStatus = "Completed";
          this.completedLessonLength += 1;
        }
      }
    });
  }
  gotoNextLesson(nextLessonModuleIndex, nextLessonIndex, nextLesson: StudentLessons) {

    this.courseService.studentLessonComplete(this.seletcedLesson.studentLessonId, nextLesson.studentLessonId, "2:30").subscribe(result => {
      if (result['status'] == 'success') {
        if (this.studentCourseDetail.modules[this.currentModuleIndex].lessons[this.currentLessonIndex].progressStatus == "Onprogress") {
          this.studentCourseDetail.modules[this.currentModuleIndex].lessons[this.currentLessonIndex].progressStatus = "Completed";
          this.completedLessonLength += 1;
          this.studentCourseDetail.modules[nextLessonModuleIndex].lessons[nextLessonIndex].progressStatus = "Onprogress"
          this.studentCourseDetail.modules[nextLessonModuleIndex].lessons[nextLessonIndex].status = true;
        }
        this.getLesson(this.studentCourseDetail.modules[nextLessonModuleIndex], nextLessonModuleIndex, nextLesson, nextLessonIndex);
      }
    });
  }
  gotoPreviousLesson(previousLessonModuleIndex, previoustLessonIndex, previousLesson) {
    this.getLesson(this.studentCourseDetail.modules[previousLessonModuleIndex], previousLessonModuleIndex, previousLesson, previoustLessonIndex);
  }

  getLesson(module: StudentModule, moduleIndex: number, lesson: StudentLessons, lessonIndex: number) {

    if (this.seletcedLesson && this.seletcedLesson.lessonId == lesson.lessonId) {
      return false;
    }
    this.takenTime = 0;
    //setInterval(() => { this.takenTime += 1 }, 1000);
    this.seletcedLesson = lesson;
    this.selectedModule = module;
    this.currentModuleIndex = moduleIndex;
    this.currentLessonIndex = lessonIndex;
    //Please make an algoritham for the condition and check again for the condition to be met
    if (moduleIndex == 0 && lessonIndex == 0) {
      this.previousLesson = null;
      if (module.lessons.length > 1) {
        this.nextLesson = module.lessons[lessonIndex + 1];
        this.nextLessonIndex = lessonIndex + 1;
        this.previousLessonIndex = null;
        this.nextLessonModuleIndex = moduleIndex;
        this.previousLessonModuleIndex = null;
      }
      else if (typeof this.studentCourseDetail.modules[moduleIndex + 1] != 'undefined')
        if (typeof this.studentCourseDetail.modules[moduleIndex + 1].lessons[0] != 'undefined') {
          this.nextLesson = this.studentCourseDetail.modules[moduleIndex + 1].lessons[0];
          this.nextLessonIndex = 0;
          this.previousLessonIndex = 0;
          this.nextLessonModuleIndex = moduleIndex + 1;
          this.previousLessonModuleIndex = moduleIndex;
        }
        else
          this.nextLesson = null;
      else
        this.nextLesson = null;
    }
    else if ((module.lessons.length - 1) == lessonIndex) {
      this.previousLesson = module.lessons[lessonIndex - 1];
      if (typeof this.studentCourseDetail.modules[moduleIndex + 1] != 'undefined') {
        if (typeof this.studentCourseDetail.modules[moduleIndex + 1].lessons[0] != 'undefined') {
          this.nextLesson = this.studentCourseDetail.modules[moduleIndex + 1].lessons[0];
          this.nextLessonIndex = 0;
          this.previousLessonIndex = lessonIndex - 1;
          this.nextLessonModuleIndex = moduleIndex + 1;
          this.previousLessonModuleIndex = moduleIndex;
        }
        else {
          this.nextLessonIndex = null;
          this.previousLessonIndex = lessonIndex - 1;
          this.nextLessonModuleIndex = null;
          this.previousLessonModuleIndex = moduleIndex;
          this.nextLesson = null;
        }
      }
      else {
        this.nextLessonIndex = null;
        this.previousLessonIndex = lessonIndex - 1;
        this.nextLessonModuleIndex = moduleIndex + 1;
        this.previousLessonModuleIndex = moduleIndex;
        this.nextLesson = null;
      }

    }
    else if (lessonIndex == 0) {

      if (typeof this.studentCourseDetail.modules[moduleIndex - 1] != 'undefined')
        if (typeof this.studentCourseDetail.modules[moduleIndex - 1].lessons[this.studentCourseDetail.modules[moduleIndex - 1].lessons.length - 1] != 'undefined') {
          this.previousLesson = this.studentCourseDetail.modules[moduleIndex - 1].lessons[this.studentCourseDetail.modules[moduleIndex - 1].lessons.length - 1];
          this.nextLessonIndex = lessonIndex + 1;
          this.previousLessonIndex = this.studentCourseDetail.modules[moduleIndex - 1].lessons.length - 1;
          this.nextLessonModuleIndex = moduleIndex;
          this.previousLessonModuleIndex = moduleIndex - 1;
        }
        else
          this.previousLesson = null;
      else
        this.previousLesson = null;
      if (module.lessons.length > 1)
        this.nextLesson = module.lessons[lessonIndex + 1];
      else
        this.nextLesson = null;
    }
    else {
      this.previousLesson = module.lessons[lessonIndex - 1];
      this.nextLessonIndex = lessonIndex + 1;
      this.previousLessonIndex = lessonIndex - 1;
      this.nextLessonModuleIndex = moduleIndex;
      this.previousLessonModuleIndex = moduleIndex;
      this.nextLesson = module.lessons[lessonIndex + 1];
    }

    // console.log('Current Lesson', this.seletcedLesson);
    // console.log('Previous Lesson', this.previousLesson);
    // console.log('Nest Lesson', this.nextLesson);

  }
  //notTime = setInterval(() => { this.takenTime += 1 }, 1000);
}
