import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/model/auth.service';
import { CategoryService } from 'src/app/model/category.service';
import { NzMessageService } from 'ng-zorro-antd';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-student-course',
  templateUrl: './student-course.component.html',
  styleUrls: ['./student-course.component.scss']
})
export class StudentCourseComponent implements OnInit {
  public courseList: Array<any> = [];
  userrole:any;
  public downloadUrl = environment.apiUrl+'certificate/download/';
  constructor(public authService: AuthService, private categoryService: CategoryService, private message: NzMessageService) { }

  ngOnInit() {
        this.userrole=this.authService.getUser().role

    this.categoryService.getStudentCourse(this.authService.getUser().id).subscribe(result => {
      console.log(result);
      if (result['status'] == 'success')
        this.courseList = result['student_categories'].map(course => ({ sCid: course.id, courseId: course.category_id, courseName: course.category_name, courseBanner: course.image, description: course.description, status: course.status, expiryStatus:Boolean(course.expiry_status) }))
      else
        this.message.create('error', result['msg']);
      //console.log(this.courseList);
    });
  }

}
